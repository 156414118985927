import apiV2 from 'API/api'
import { APIRouters } from 'API/APIRouters'
import { ISuccessResponse } from 'API/interface'
import { ILeaveTypeListResponse } from 'types/leaveRequest'

export async function getLeaveTypeListAPI(): Promise<ISuccessResponse<ILeaveTypeListResponse>> {
  try {
    const response = await apiV2.get(APIRouters.leaveType.list.value)
    return response
  } catch (error) {
    throw error
  }
}
