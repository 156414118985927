import { useEffect } from 'react'
import { VStack, Tag } from '@chakra-ui/react'
import Table from 'components/Table'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import { observer } from 'mobx-react'
import { backgroundBlue500 } from 'theme/globalStyles'
import { getValidArray } from 'utils/commonUtils'
import { useStores } from 'utils/hooks/useStores'
import { getLeaveTypeHeaderList } from '../constants'

const LeaveType = () => {
  const { adminLeaveTypeStore } = useStores()
  const { leaveTypeList, isLoadingLeaveTypeList } = adminLeaveTypeStore

  useEffect(() => {
    adminLeaveTypeStore.getLeaveTypeList()
  }, [])

  const tableData = getValidArray(leaveTypeList).map((leaveType) => {
    if (leaveType.isDefault) {
      return {
        ...leaveType,
        actions: (
          <Tag
            backgroundColor={backgroundBlue500}
            size="md"
            variant="solid"
            fontWeight="400"
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="8px"
            paddingX="8px"
            paddingY="2px">
            Default
          </Tag>
        ),
      }
    }

    const actions = (
      <OptionButtonsWithoutDropBox
        isEdit={true}
        isDelete={true}
        editActionHandler={() => {}}
        deleteActionHandler={() => {}}
      />
    )
    return {
      ...leaveType,
      actions,
    }
  })

  return (
    <VStack width="full">
      <Table
        isLoading={isLoadingLeaveTypeList}
        headerList={getLeaveTypeHeaderList()}
        tableData={tableData}
        isStriped
        isShowPagination={true}
        hasBottomHeader={false}
      />
    </VStack>
  )
}

export default observer(LeaveType)
