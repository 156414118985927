import { capitalize } from 'lodash'
import { IOption } from './common'
import {
  EPaymentCategoryOfProject,
  EPaymentCategoryFromClientOfProject,
  ERoleOfUser,
  EContractTypes,
  EPriorityType,
  EStateType,
  ELaborContractCategories,
  ELaborContractTerm,
  EYesNoOption,
  EPartnerCategory,
  EUserEducation,
  EGender,
  EGenderVI,
  EChartType,
  EPartnerType,
  EUserStatusLabel,
  EUserJobTitleVI,
} from './enum'

export const PaymentCategories = [EPaymentCategoryOfProject.HOURLY, EPaymentCategoryOfProject.FIXED_PRICE]
export const PaymentCategoriesFromClient = [
  EPaymentCategoryFromClientOfProject.TIME_AND_MATERIAL,
  EPaymentCategoryFromClientOfProject.MONTHLY_FIXED_SCOPE,
]
export const UserRole = [ERoleOfUser.ADMIN, ERoleOfUser.USER]
export const YesNoOption = [EYesNoOption.YES, EYesNoOption.NO]
export const ReportPeriods = ['day', 'week', 'month']
export const NotificationId = 'notificationId'
export const ExportCSVFileHeaders = [
  { label: 'Task Name', value: 'taskName' },
  { label: 'Description', value: 'description' },
  { label: 'Start Time', value: 'startTime' },
  { label: 'End Time', value: 'endTime' },
  { label: 'Time Total', value: 'timeTotal' },
  { label: 'Rate Exchange', value: 'rateExchange' },
  { label: 'Hourly Rate', value: 'defaultRating' },
  { label: 'Salary (VND)', value: 'salary' },
  { label: 'Total Salary(VND)', value: 'totalSalary' },
  { label: 'Total Working Time(hours)', value: 'totalWorkingTime' },
]
export const ExportCSVFileHeadersOfTotalSheet = [
  { label: 'Time Of Fresher', value: 'fresher' },
  { label: 'Time Of Junior', value: 'junior' },
  { label: 'Time Of Senior', value: 'senior' },
  { label: 'Time Of Lead', value: 'lead' },
]
export const MonthOfYear = [
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'Octorber', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
]

export const ChartListForTimeAndMaterialProject = [
  { label: 'Time total chart', value: EChartType.TIME_TOTAL_CHART },
  { label: 'Renenue chart', value: EChartType.REVENUE_CHART_FOR_TIME_AND_MATERIAL },
]

export const ChartListForMonthlyFixedScopeProject = [
  { label: 'Time total chart', value: EChartType.TIME_TOTAL_CHART },
  { label: 'Renenue chart', value: EChartType.REVENUE_CHART_FOR_MONTHLY_FIXED_SCOPE },
]

export const TypeOfContract = [
  EContractTypes.FREELANCER_CONTRACT,
  EContractTypes.LABOR_CONTRACT,
  EContractTypes.CONFIDENTIALITY_AGREEMENT,
]

export const TypeOfPriority = [EPriorityType.HIGH, EPriorityType.MEDIUM, EPriorityType.LOW]
export const TypeOfState = [
  EStateType.TODO,
  EStateType.IN_PROGRESS,
  EStateType.NEED_REVIEW,
  EStateType.FIXING,
  EStateType.COMPLETE,
]

export const LaborContractCategory = [ELaborContractCategories.OFFICIAL, ELaborContractCategories.PROBATION]
export const LaborContractTerm = [ELaborContractTerm.DEFINITE_TERM, ELaborContractTerm.INDEFINITE_TERM]
export const PartnerCategory = [
  EPartnerCategory.OWNER,
  EPartnerCategory.CLIENT,
  EPartnerCategory.SUPPLIER,
  EPartnerCategory.EMPLOYEE,
  EPartnerCategory.FREELANCER,
  EPartnerCategory.BANK,
  EPartnerCategory.SET,
]

export const PartnerCategoryForStaffDocument = [
  EPartnerCategory.EMPLOYEE,
  EPartnerCategory.FREELANCER,
  EPartnerCategory.SET,
]

export const jobTitleVIOptions: IOption[] = [
  {
    label: capitalize(EUserJobTitleVI.SALE),
    value: EUserJobTitleVI.SALE,
  },
  {
    label: capitalize(EUserJobTitleVI.ACCOUNTING),
    value: EUserJobTitleVI.ACCOUNTING,
  },
  {
    label: capitalize(EUserJobTitleVI.BA),
    value: EUserJobTitleVI.BA,
  },
  {
    label: capitalize(EUserJobTitleVI.ENGINEER),
    value: EUserJobTitleVI.ENGINEER,
  },
  {
    label: capitalize(EUserJobTitleVI.QA_QC),
    value: EUserJobTitleVI.QA_QC,
  },
]

export const PartnerCategoryOfBirthday = ['employee', 'freelancer']
export const PartnerType = ['organizations', 'personal']

export const partnerTypes: IOption[] = [
  {
    label: capitalize(EPartnerType.ORGANIZATIONS),
    value: EPartnerType.ORGANIZATIONS,
  },
  {
    label: capitalize(EPartnerType.PERSONAL),
    value: EPartnerType.PERSONAL,
  },
]

export const educationOptions: IOption[] = [
  {
    value: EUserEducation.UNDERGRADUATE,
    label: capitalize(EUserEducation.UNDERGRADUATE),
  },
  {
    value: EUserEducation.BACHELOR,
    label: capitalize(EUserEducation.BACHELOR),
  },
  {
    value: EUserEducation.MASTER,
    label: capitalize(EUserEducation.MASTER),
  },
  {
    value: EUserEducation.DOCTOR,
    label: capitalize(EUserEducation.DOCTOR),
  },
  {
    value: EUserEducation.CERTIFICATE,
    label: capitalize(EUserEducation.CERTIFICATE),
  },
]

export const genderOptions: IOption[] = [
  {
    label: EGender.MALE,
    value: EGender.MALE,
  },
  {
    label: EGender.FEMALE,
    value: EGender.FEMALE,
  },
]

export const genderVIOptions: IOption[] = [
  {
    label: EGenderVI.MALE,
    value: EGenderVI.MALE,
  },
  {
    label: EGenderVI.FEMALE,
    value: EGenderVI.FEMALE,
  },
]

export const citizenIssuePlaceOptions: IOption[] = [
  {
    value: 'Cục trưởng cục CS QLHC Về TTXH',
    label: 'Cục trưởng cục CS QLHC Về TTXH',
  },
]

export const allOptionItem: IOption = { value: 'all', label: 'Select All Options' }

export const statusOptions: IOption[] = [
  {
    label: capitalize(EUserStatusLabel.ARCHIVED),
    value: EUserStatusLabel.ARCHIVED,
  },
  {
    label: capitalize(EUserStatusLabel.DELETED),
    value: EUserStatusLabel.DELETED,
  },
]
