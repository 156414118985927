import { FormControl, FormLabel } from '@chakra-ui/form-control'
import { Box, HStack, SimpleGrid, Text } from '@chakra-ui/layout'
import StatusCell from 'components/Table/DesktopTable/components/StatusCell'
import { textGrey500, textGrey700 } from 'theme/globalStyles'
import { ELeaveType } from 'constants/enum'
import { ILeaveDetailFormProps } from './interface'

function LeaveDetailForm(props: ILeaveDetailFormProps) {
  const { leaveSelectedData } = props
  //TODO: implement remainingLeaveDays later
  const { dateCreated, timeRange, user, leaveType, status, commitTime, reason, remainingLeaveDays } = leaveSelectedData
  return (
    <Box>
      <SimpleGrid columns={1} gap="24px">
        <FormControl>
          <FormLabel fontWeight={400} color={textGrey500}>
            Start date - End date
          </FormLabel>
          <Text margin={0}>{timeRange}</Text>
        </FormControl>
        <FormControl>
          <FormLabel fontWeight={400} color={textGrey700}>
            User
          </FormLabel>
          <Text margin={0} fontWeight={500} color={textGrey700}>
            {user}
          </Text>
        </FormControl>
        <HStack>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Type
            </FormLabel>
            <StatusCell status={leaveType}>{leaveType}</StatusCell>
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Commit time
            </FormLabel>
            <Text margin={0}>{leaveType === ELeaveType.REMOTE ? commitTime : '--'}</Text>
          </FormControl>
        </HStack>
        <HStack>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Reason
            </FormLabel>
            <Text margin={0} fontWeight={500}>
              {reason}
            </Text>
          </FormControl>
        </HStack>
        <HStack>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Created date
            </FormLabel>
            <Text margin={0}>{dateCreated}</Text>
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Status
            </FormLabel>
            <StatusCell status={status}>{status}</StatusCell>
          </FormControl>
        </HStack>
      </SimpleGrid>
    </Box>
  )
}

export default LeaveDetailForm
