import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import XLSX, { WorkBook, WorkSheet } from 'xlsx-js-style'
import { TooltipTitle } from '../../../constants/enum'

export function getCompatibleDataForExport(tableData: object[], header: string[]): {}[] {
  if (header.length === 0) {
    return tableData
  }

  const compatibleData: {}[] = tableData?.map((row) => {
    const result = {}
    header?.forEach((col) => {
      //* INFO: Some fields may overwrote by table item
      result[col] = get(row, `${col}.props.children`, get(row, `${col}`, '')) || ''
      if (col === 'isArchived') {
        result[col] = result[col] ? 'Archived' : 'Not Archived'
      }

      if (col === 'isDeleted') {
        result[col] = result[col] ? 'Deleted' : 'Not Deleted'
      }

      if (col === 'isActive') {
        result[col] = result[col] ? 'Active' : 'Not Active'
      }
    })
    return result
  })
  return compatibleData
}

export function getExportFileBlob(tableData: object[], excludedList: string[], name: string): void {
  const header: string[] = Object.keys(get(tableData, '0', {}) || {}).filter((e) => !excludedList.includes(e))
  const compatibleData: {}[] = getCompatibleDataForExport(tableData, header)
  let blankWorkBook: WorkBook = XLSX.utils.book_new()
  let sheet: WorkSheet = XLSX.utils.json_to_sheet(compatibleData, {
    header,
  })
  let workSheetData: WorkSheet = cloneDeep(sheet)
  const textColor = { rgb: '00000000' }
  const thinBorder = { style: 'thin', color: textColor }
  const borderStyle = {
    top: thinBorder,
    bottom: thinBorder,
    left: thinBorder,
    right: thinBorder,
    diagonalUp: true,
    diagonalDown: true,
  }
  Array.from(Array(header.length)).forEach((_, index) => {
    const charAddress = String.fromCharCode(65 + index)
    set(workSheetData[`${charAddress}1`], 's', {
      font: {
        sz: 16,
        bold: true,
        shadow: true,
        color: textColor,
      },
      border: borderStyle,
    })
    Array.from(Array(compatibleData.length)).forEach((_, rowIndex) => {
      const address = `${charAddress}${rowIndex + 2}`
      set(workSheetData[address], 's', {
        font: {
          sz: 14,
          color: textColor,
        },
        border: borderStyle,
      })
    })
  })
  XLSX.utils.book_append_sheet(blankWorkBook, workSheetData, 'React Table Data')
  XLSX.writeFile(blankWorkBook, `${name}.xlsx`)
}

export function getTooltipTitle(header: string, accessor: string): TooltipTitle | null {
  switch (header) {
    case 'Start Date - End Date':
      return TooltipTitle.VIEW_DETAIL
    case 'User':
      return TooltipTitle.VIEW_DETAIL
    case 'Created Date':
      return TooltipTitle.VIEW_DETAIL
    case '':
      switch (accessor) {
        case 'actionsAccepted':
          return TooltipTitle.APPROVE
        case 'actionsRejected':
          return TooltipTitle.REJECT
        default:
          return null
      }
    default:
      return null
  }
}
