import { handleError } from 'API/error'
import { ISuccessAggregateResponse } from 'API/interface'
import { adminAggregateLeaveRequestAPI, adminReviewLeaveRequestAPI } from 'API/leaveRequest'
import get from 'lodash/get'
import { makeAutoObservable } from 'mobx'
import queryString from 'query-string'
import { THistory } from 'types/common'
import { ILeaveRequest } from 'types/leaveRequest'
import { ELeaveRequestStatus } from 'constants/enum'
import { defaultPage, defaultPageSize } from 'constants/index'
import { getValidArray } from 'utils/commonUtils'
import RootStore from '../rootStore'

class AdminLeaveRequestStore {
  rootStore: RootStore
  userLeaveList: ILeaveRequest[] = []
  isLoading: boolean = false
  isLeaveListLoading: boolean = false
  count: number = 0

  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }
  // TODO: filter feature will add in future
  public async adminAggregateLeaveRequest(history?: THistory): Promise<void> {
    try {
      this.isLeaveListLoading = true
      const searchString = history?.location?.search
      const parsedQuery = queryString.parse(searchString)
      const currentPage: number = Number(get(parsedQuery, 'page', defaultPage))
      const response: ISuccessAggregateResponse<ILeaveRequest[]> = await adminAggregateLeaveRequestAPI({
        currentPage,
        pageSize: defaultPageSize,
      })
      this.userLeaveList = getValidArray(response?.data)
      this.count = response?.totalCount ?? 0
    } catch (error) {
      handleError(error, 'src/store/admin/leaveRequestStore.ts', 'adminAggregateLeaveRequest')
    } finally {
      this.isLeaveListLoading = false
    }
  }

  public async adminReviewLeaveRequest(id: string, status: ELeaveRequestStatus) {
    try {
      this.isLoading = true
      await adminReviewLeaveRequestAPI(id, { status: status })
    } catch (error) {
      handleError(error, 'src/store/admin/leaveRequestStore.ts', 'adminReviewLeaveRequest')
    } finally {
      this.isLoading = false
    }
  }
}

export default AdminLeaveRequestStore
