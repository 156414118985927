import { useEffect, useState } from 'react'
import { Box, Flex, useMediaQuery } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { getErrorMessage } from 'API/error'
import { History } from 'history'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import Table from '../../../components/Table'
import MoreDropdown from '../../../components/Table/DesktopTable/components/MoreDropdown'
import { limitItemPerPage, Messages } from '../../../constants'
import { maxMobileSize } from '../../../constants/common'
import { ELeaveRequestStatus, ELeaveStatus } from '../../../constants/enum'
import { frontendRoutes } from '../../../constants/routes'
import { backgroundGrey200 } from '../../../theme/globalStyles'
import { ILeaveFilter, ILeaveRequest, ITableLeaveData } from '../../../types/leaveRequest'
import { getValidArray } from '../../../utils/commonUtils'
import { useStores } from '../../../utils/hooks/useStores'
import LeaveModal from './components/LeaveModal'
import { getHeaderList } from './constant'
import { convertLeaveRequestsToTableFormat } from './container'
import styles from './styles.module.scss'

const AdminLeaveRequest = () => {
  const history: History = useHistory()
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)
  const { adminLeaveRequestStore } = useStores()
  const [leaveRequestSelected, setLeaveRequestSelected] = useState<ITableLeaveData>(null)
  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false)

  const { userLeaveList, count } = adminLeaveRequestStore

  function openDetailModal(leaveRequest: ITableLeaveData) {
    setLeaveRequestSelected(leaveRequest)
    setIsDetailModalOpen(true)
  }

  function closeDetailModal() {
    setIsDetailModalOpen(false)
  }

  const [filter, setFilter] = useState<ILeaveFilter>(initialFilter ?? {})

  const pageIndex: number = Number(get(filter, 'page', 1))
  const pagination = {
    includePagination: true,
    pageIndex: pageIndex,
    pageSize: limitItemPerPage,
    tableLength: count,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page }
      setFilter(changedFilter)
      history.push({
        pathname: frontendRoutes.leaveManagementPage.leaveManagementForAdmin.myLeave.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })

      adminLeaveRequestStore.adminAggregateLeaveRequest(history)
    },
  }

  async function updateLeaveStatus(id: string, status: ELeaveRequestStatus) {
    try {
      await adminLeaveRequestStore.adminReviewLeaveRequest(id, status)
      toast.success(Messages.updateLeaveRequestSuccess)
      await adminLeaveRequestStore.adminAggregateLeaveRequest(history)
    } catch (error) {
      const errorMessage: string = getErrorMessage(error)
      toast.error(errorMessage)
    }
  }

  useEffect(() => {
    adminLeaveRequestStore.adminAggregateLeaveRequest(history)
  }, [])

  const dataInTable = convertLeaveRequestsToTableFormat(getValidArray<ILeaveRequest>(userLeaveList)).map(
    (leaver: any) => {
      return {
        ...leaver,
        dateCreated: (
          <Text className={styles.textHover} onClick={() => openDetailModal(leaver)}>
            {leaver.dateCreated}
          </Text>
        ),
        timeRange: (
          <Text className={styles.textHover} onClick={() => openDetailModal(leaver)}>
            {leaver.timeRange}
          </Text>
        ),
        user: (
          <Text className={styles.textHover} onClick={() => openDetailModal(leaver)}>
            {leaver.user}
          </Text>
        ),
        actionsAccepted:
          leaver.status === ELeaveStatus.PENDING ? (
            <MoreDropdown
              isAccepted={true}
              acceptActionHandler={() => updateLeaveStatus(leaver.id, ELeaveRequestStatus.APPROVED)}
              isLoading={adminLeaveRequestStore.isLoading}
            />
          ) : null,
        actionsRejected:
          leaver.status === ELeaveStatus.PENDING ? (
            <MoreDropdown
              isReject={true}
              rejectActionHandler={() => updateLeaveStatus(leaver.id, ELeaveRequestStatus.REJECTED)}
              isLoading={adminLeaveRequestStore.isLoading}
            />
          ) : null,
      }
    }
  )

  return (
    <Box
      className={styles.leaveContainer}
      background="white"
      padding={6}
      borderRadius="6px"
      border={`1px solid ${backgroundGrey200}`}>
      {isDetailModalOpen && (
        <LeaveModal
          isLoading={adminLeaveRequestStore.isLoading}
          isOpen={isDetailModalOpen}
          saveHandler={updateLeaveStatus}
          closeHandler={closeDetailModal}
          leaveSelectedData={leaveRequestSelected}
        />
      )}
      <Flex justifyContent="space-between" flexWrap="wrap">
        {/* TODO: Add search feature */}
        {/* <HStack marginBottom={6} width={{ base: '100%', md: 'auto' }}>
          <InputGroup
            border={`1px solid ${backgroundGrey200}`}
            borderRadius="6px"
            width={{ base: '100%', lg: '540px' }}
            background="white">
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.400" />
            </InputLeftElement>
            <Input type="search" placeholder="Search request by name..." onChange={() => {}} />
          </InputGroup>
        </HStack> */}
      </Flex>
      <Table
        headerList={getHeaderList(isMobile)}
        isLoading={adminLeaveRequestStore.isLeaveListLoading}
        tableData={dataInTable}
        isStriped
        pagination={pagination}
        isShowPagination={true}
      />
    </Box>
  )
}

export default observer(AdminLeaveRequest)
