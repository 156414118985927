import { useState, useEffect } from 'react'
import { Box, useMediaQuery } from '@chakra-ui/react'
import ConfirmYesNoDeleteModal from 'components/ConfirmYesNoDeleteModal'
import Table from 'components/Table'
import { History } from 'history'
import get from 'lodash/get'
import { observer } from 'mobx-react'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { backgroundGrey200 } from 'theme/globalStyles'
import { ILeaveFilter, ILeaveRequest, ILeaveRequestFormData } from 'types/leaveRequest'
import { maxMobileSize, maxTabletSize } from 'constants/common'
import { ELeaveManagementSubTitle } from 'constants/enum'
import { defaultPage, defaultPageSize } from 'constants/index'
import { Messages } from 'constants/index'
import { frontendRoutes } from 'constants/routes'
import { useStores } from 'utils/hooks/useStores'
import HeaderSection from './components/HeaderSection'
import LeaveRequestDetailModal from './components/LeaveRequestDetailModal'
import LeaveRequestModal from './components/LeaveRequestModal'
import LeaveRequestSummaryModal from './components/LeaveRequestSummaryModal'
import { getHeaderList } from './constant'
import { convertLeaveRequestsToTableFormat } from './container'
import styles from './styles.module.scss'

const UserLeaveRequest = () => {
  window.sessionStorage.setItem('subPageTitle', ELeaveManagementSubTitle.MY_LEAVE)
  window.dispatchEvent(new Event('storage'))

  const [isMobile] = useMediaQuery(maxMobileSize)
  const [isTablet] = useMediaQuery(maxTabletSize)

  const history: History = useHistory()

  const { userLeaveRequestStore, leaveTypeStore } = useStores()
  const { leaveRequestList, isLoadingSubmit, isLoadingList, totalCount } = userLeaveRequestStore

  const initialFilter = queryString.parse(history.location.search, { parseBooleans: true })

  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState<ILeaveRequest>(null)
  const [filter, setFilter] = useState<ILeaveFilter>(initialFilter ?? {})
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false)
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState<boolean>(false)
  const [isDetailModalOpen, setIsDetailModalOpen] = useState<boolean>(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false)
  const [isOpenDeleteLeaveConfirm, setIsOpenDeleteLeaveConfirm] = useState<boolean>(false)

  function openCreateModal() {
    setIsCreateModalOpen(true)
  }

  function closeCreateModal() {
    setIsCreateModalOpen(false)
  }

  function openSummaryModal(leaveRequest: ILeaveRequest) {
    setSelectedLeaveRequest(leaveRequest)
    setIsSummaryModalOpen(true)
  }

  function closeSummaryModal() {
    setIsSummaryModalOpen(false)
  }

  function openDetailModal(leaveRequest: ILeaveRequest) {
    setSelectedLeaveRequest(leaveRequest)
    setIsDetailModalOpen(true)
  }

  function closeDetailModal() {
    setIsDetailModalOpen(false)
  }

  function openEditModal(leaveRequest: ILeaveRequest) {
    setSelectedLeaveRequest(leaveRequest)
    setIsEditModalOpen(true)
  }

  function closeEditModal() {
    setIsEditModalOpen(false)
  }

  function openConfirmDeleteModal(leaveRequest: ILeaveRequest) {
    setSelectedLeaveRequest(leaveRequest)
    setIsOpenDeleteLeaveConfirm(true)
  }

  function closeConfirmDeleteModal() {
    setIsOpenDeleteLeaveConfirm(false)
  }

  async function handleCreateLeaveRequest(data: ILeaveRequestFormData) {
    try {
      await userLeaveRequestStore.userCreateLeaveRequest(data)
      closeCreateModal()
      userLeaveRequestStore.userAggregateLeaveRequest({
        currentPage: 1,
        pageSize: defaultPageSize,
      })
    } catch {}
  }

  async function handleEditLeaveRequest(data: ILeaveRequestFormData) {
    try {
      await userLeaveRequestStore.userUpdateLeaveRequest(selectedLeaveRequest.id, data)
      closeEditModal()
      toast.success(Messages.editLeaveRequeSuccess)
      userLeaveRequestStore.userAggregateLeaveRequest({
        currentPage: 1,
        pageSize: defaultPageSize,
      })
    } catch {}
  }

  async function handleDeleteLeaveRequest(id: string): Promise<void> {
    await userLeaveRequestStore.userDeleteLeaveRequest(id)
    setIsOpenDeleteLeaveConfirm(false)
    await userLeaveRequestStore.userAggregateLeaveRequest({
      currentPage: 1,
      pageSize: defaultPageSize,
    })
  }

  const pageIndex: number = Number(get(filter, 'page', defaultPage))
  const pagination = {
    includePagination: true,
    pageIndex,
    pageSize: defaultPageSize,
    tableLength: totalCount,
    gotoPage: (page: number) => {
      const changedFilter = { ...filter, page }
      setFilter(changedFilter)
      history.push({
        pathname: frontendRoutes.leaveManagementPage.leaveManagementForUser.myLeave.value,
        state: { page, filter },
        search: queryString.stringify(changedFilter),
      })
    },
  }

  const dataInTable =
    convertLeaveRequestsToTableFormat(
      leaveRequestList,
      openSummaryModal,
      openDetailModal,
      openEditModal,
      openConfirmDeleteModal
    ) ?? []

  useEffect(() => {
    leaveTypeStore.getUserLeaveTypeList()
  }, [])

  useEffect(() => {
    userLeaveRequestStore.userAggregateLeaveRequest({
      currentPage: pageIndex,
      pageSize: defaultPageSize,
    })
  }, [filter])

  return (
    <Box>
      <Box
        className={styles.leaveContainer}
        background={isMobile ? 'none' : 'white'}
        paddingY={isMobile ? 2 : 4}
        paddingX={isMobile ? 0 : 4}
        borderRadius="6px"
        border={isMobile ? 'none' : `1px solid ${backgroundGrey200}`}>
        <HeaderSection handleCreate={openCreateModal} />
        <Box className={styles.body} paddingY={3} paddingX={4}>
          {/* TODO: Add remaining leave days */}
          {/* <Icon iconName="information-blue-fill-circle.svg" size={19} />
          <Text margin="0 0 0 5px">
            Your remaining leave days: <span> 6 days </span>
          </Text> */}

          <LeaveRequestModal
            isLoading={isLoadingSubmit}
            isOpen={isCreateModalOpen}
            closeHandler={closeCreateModal}
            saveHandler={handleCreateLeaveRequest}
          />

          <LeaveRequestModal
            selectedLeaveRequest={selectedLeaveRequest}
            isLoading={isLoadingSubmit}
            isEdit={true}
            isOpen={isEditModalOpen}
            closeHandler={closeEditModal}
            saveHandler={handleEditLeaveRequest}
          />
          <ConfirmYesNoDeleteModal
            isOpen={isOpenDeleteLeaveConfirm}
            title="leave request"
            closeHandler={closeConfirmDeleteModal}
            OKClickHandler={handleDeleteLeaveRequest}
            itemId={selectedLeaveRequest?.id}
          />
          <LeaveRequestDetailModal
            isOpen={isDetailModalOpen}
            closeHandler={closeDetailModal}
            selectedLeaveRequest={selectedLeaveRequest}
          />
          <LeaveRequestSummaryModal
            isOpen={isSummaryModalOpen}
            handleCloseModal={closeSummaryModal}
            selectedLeaveRequest={selectedLeaveRequest}
          />
        </Box>
        <Table
          headerList={getHeaderList(isTablet)}
          isLoading={isLoadingList}
          tableData={dataInTable}
          isStriped
          pagination={pagination}
          hasBottomHeader={!isMobile}
        />
      </Box>
    </Box>
  )
}
export default observer(UserLeaveRequest)
