import { Flex, HStack, useMediaQuery } from '@chakra-ui/react'
import Button from 'components/Button'
import { maxMobileSize } from 'constants/common'

interface IHeaderSectionProps {
  handleCreate: () => void
}

const HeaderSection = (props: IHeaderSectionProps) => {
  const { handleCreate } = props
  const [isMobile]: boolean[] = useMediaQuery(maxMobileSize)

  return (
    <Flex
      justifyContent="flex-end"
      flexWrap="wrap"
      paddingX={isMobile ? 4 : 0}
      paddingTop={isMobile ? 4 : 0}
      height={isMobile ? 10 : 'auto'}>
      {/* TODO: Add filter feature */}
      {/* <HStack marginBottom={6}>
        <Button leftIcon="filter.svg" variant="outline">
          {!isMobile && 'Filter'}
        </Button>
      </HStack> */}
      <HStack marginBottom={6}>
        <Button variant="solid" leftIcon="add-white.svg" onClick={handleCreate}>
          {!isMobile && 'Create leave request'}
        </Button>
      </HStack>
    </Flex>
  )
}

export default HeaderSection
