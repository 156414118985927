import { ReactNode } from 'react'
import { Text } from '@chakra-ui/react'
import MoreDropdown from 'components/Table/DesktopTable/components/MoreDropdown'
import OptionButtonsWithoutDropBox from 'components/Table/DesktopTable/components/OptionButtonsWithoutDropBox'
import { IAction } from 'components/Table/TabletTable/components/MoreAction/interfaces'
import { ILeaveRequest } from 'types/leaveRequest'
import { getFormattedDateWith_DDMMYYYY, getFormattedTimeDate } from 'utils/dateFormatUtils'
import { ELeaveRequestStatus } from '../../../constants/enum'
import styles from './styles.module.scss'

export function convertLeaveRequestsToTableFormat(
  leaveRequests: ILeaveRequest[],
  openSummaryModal: (leaveRequest: ILeaveRequest) => void,
  openDetailModal: (leaveRequest: ILeaveRequest) => void,
  openEditModal: (leaveRequest: ILeaveRequest) => void,
  openConfirmDeleteModal: (leaveRequest: ILeaveRequest) => void
) {
  return leaveRequests?.map((leaveRequest: ILeaveRequest) => {
    let approverName = ''
    let mobileActions: IAction[] = []
    let desktopActions: ReactNode
    if (leaveRequest.status === ELeaveRequestStatus.APPROVED || leaveRequest.status === ELeaveRequestStatus.REJECTED) {
      approverName = leaveRequest.decidedBy
    }
    if (leaveRequest.status === ELeaveRequestStatus.PENDING) {
      mobileActions = [
        {
          name: 'Edit',
          handler: () => openEditModal(leaveRequest),
        },
        {
          name: 'Delete',
          handler: () => openConfirmDeleteModal(leaveRequest),
        },
      ]
      desktopActions = (
        <OptionButtonsWithoutDropBox
          isEdit={true}
          isDelete={true}
          editActionHandler={() => openEditModal(leaveRequest)}
          deleteActionHandler={() => openConfirmDeleteModal(leaveRequest)}
        />
      )
    }

    return {
      id: leaveRequest.id,
      dateCreated: (
        <Text className={styles.textHover} onClick={() => openDetailModal(leaveRequest)}>
          {getFormattedDateWith_DDMMYYYY(leaveRequest.createdAt)}
        </Text>
      ),
      timeRangeMobile: (
        <Text>
          {`${getFormattedTimeDate(leaveRequest.startTime ?? '')}
          - ${getFormattedTimeDate(leaveRequest.endTime ?? '')}`}
        </Text>
      ),
      timeRange: (
        <Text className={styles.textHover} onClick={() => openDetailModal(leaveRequest)}>
          {`${getFormattedTimeDate(leaveRequest.startTime ?? '')}
          - ${getFormattedTimeDate(leaveRequest.endTime ?? '')}`}
        </Text>
      ),
      user: (
        <Text className={styles.textHover} onClick={() => openDetailModal(leaveRequest)}>
          {leaveRequest.createdBy}
        </Text>
      ),
      leaveType: leaveRequest.leaveType,
      status: leaveRequest.status,
      commitTime: leaveRequest.commitTime,
      reason: leaveRequest.reason,
      desktopActions: desktopActions,
      mobileActions: (
        <MoreDropdown
          isSummary={true}
          summaryActionHandler={() => openSummaryModal(leaveRequest)}
          actions={mobileActions}
        />
      ),
    }
  })
}
