import { ILeaveRequest } from 'types/leaveRequest'
import { getFormattedDateWith_DDMMYYYY, getFormattedTimeDate } from 'utils/dateFormatUtils'
import { ELeaveRequestStatus } from '../../../constants/enum'

export function convertLeaveRequestsToTableFormat(leaveRequests: ILeaveRequest[]) {
  return leaveRequests.map((leaveRequest: ILeaveRequest) => {
    let approverName = ''
    if (leaveRequest.status === ELeaveRequestStatus.APPROVED || leaveRequest.status === ELeaveRequestStatus.REJECTED) {
      approverName = leaveRequest.decidedBy
    }

    return {
      id: leaveRequest.id,
      dateCreated: getFormattedDateWith_DDMMYYYY(leaveRequest.createdAt),
      timeRange:
        getFormattedTimeDate(leaveRequest.startTime ?? '') + '\n- ' + getFormattedTimeDate(leaveRequest.endTime ?? ''),
      user: leaveRequest.createdBy,
      approver: approverName,
      leaveType: leaveRequest.leaveType,
      status: leaveRequest.status,
      commitTime: leaveRequest.commitTime,
      reason: leaveRequest.reason,
    }
  })
}
