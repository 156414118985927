import { Box, Text, HStack } from '@chakra-ui/layout'
import { FormControl, FormLabel, SimpleGrid } from '@chakra-ui/react'
import StatusCell from 'components/Table/DesktopTable/components/StatusCell'
import { textGrey500 } from 'theme/globalStyles'
import { ELeaveType } from 'constants/enum'
import { getFormattedDateWith_MMMDDYYYY, getFormattedTimeDate } from 'utils/dateFormatUtils'
import { ILeaveRequestDetailFormProps } from './interface'

export default function LeaveRequestDetailForm(props: ILeaveRequestDetailFormProps) {
  const { selectedLeaveRequest } = props

  return (
    <Box>
      <SimpleGrid columns={1} gap="24px">
        <FormControl>
          <FormLabel fontWeight={400} color={textGrey500}>
            Start date - End date
          </FormLabel>
          <Text margin={0}>
            {getFormattedTimeDate(selectedLeaveRequest?.startTime)} -{' '}
            {getFormattedTimeDate(selectedLeaveRequest?.endTime)}
          </Text>
        </FormControl>
        <HStack>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Type
            </FormLabel>
            <StatusCell status={selectedLeaveRequest?.leaveType}>{selectedLeaveRequest?.leaveType}</StatusCell>
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Commit time
            </FormLabel>
            <Text margin={0}>
              {selectedLeaveRequest?.leaveType === ELeaveType.REMOTE ? selectedLeaveRequest?.commitTime : '--'}
            </Text>
          </FormControl>
        </HStack>
        <FormControl>
          <FormLabel fontWeight={400} color={textGrey500}>
            Reason
          </FormLabel>
          <Text margin={0} fontWeight={500}>
            {selectedLeaveRequest?.reason}
          </Text>
        </FormControl>
        <HStack>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Created date
            </FormLabel>
            <Text margin={0}>{getFormattedDateWith_MMMDDYYYY(selectedLeaveRequest?.createdAt)}</Text>
          </FormControl>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Status
            </FormLabel>
            <StatusCell status={selectedLeaveRequest?.status}>{selectedLeaveRequest?.status}</StatusCell>
          </FormControl>
        </HStack>
        <HStack>
          <FormControl>
            <FormLabel fontWeight={400} color={textGrey500}>
              Approval Authority
            </FormLabel>
            <Text margin={0}>{selectedLeaveRequest?.decidedBy ?? '--'}</Text>
          </FormControl>
        </HStack>
      </SimpleGrid>
    </Box>
  )
}
