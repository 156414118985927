import { handleError } from 'API/error'
import { ISuccessResponse } from 'API/interface'
import { getAllNameProjectListOfUserAPI } from 'API/project'
import { IProjectNameListResponse, IProjectNameResponse } from 'API/project/constants'
import { getFixedProjectRatingV2API, getHourlyProjectRatingV2API } from 'API/projectRating'
import { IProjectRatings } from 'API/projectRating/constants'
import { getAverageSkillAPI, getSkillAPI } from 'API/skill'
import { getTechnologyListAPI } from 'API/technology/index'
import {
  changePasswordAPI,
  getDashboardUser,
  getUserProfileAPI,
  updateUserDataAPI,
  updateUserProfileAPI,
  getIsSupervisorAPI,
} from 'API/user'
import { IChangePasswordResponse, IUpdateUserProfileResponse, IUserProfileResponse } from 'API/user/constants'
import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { toast } from 'react-toastify'
import { ICreatableOption } from 'types/creatableOption'
import { IDashboardUser } from 'types/dashboard-user'
import { IPartner } from 'types/partner'
import { IProject } from 'types/project'
import { ITechnology } from 'types/technology'
import {
  IFormattedProfileBeforeRenderFE,
  IFormChangePassword,
  IProfileDetailOfDetailForm,
  IUser,
  ISkill,
} from 'types/user'
import { EWorkingHourPeriods } from 'constants/enum'
import { parseJson } from 'utils/commonUtils'
import { getParsedUserValueBeforeSendBE, getParsedUserValueBeforeRenderFEV2 } from 'utils/formatDataUtils'
import { trackIdentify } from 'utils/tracking'
import { Messages } from '../../constants'
import RootStore from '../rootStore'

class UserProfileStore {
  rootStore: RootStore
  userList: IUser[]
  projectList: IProject[] = []
  partnerList: IPartner[] = []
  creatableOption: ICreatableOption[] = []
  projectNameList: IProjectNameResponse[] = []
  profileDetail: IProfileDetailOfDetailForm
  projectRatings: IProjectRatings
  technologyList: ITechnology[] = []
  cvFileName: string
  isSupervisor: boolean
  isDashboardLoading: boolean = false
  dashboardData: IDashboardUser = {
    displayName: '',
    fullName: '',
    totalSalary: 0,
    totalWorkingTime: 0,
    lastTotalSalary: 0,
    lastTotalWorkingTime: 0,
    numberOfProjects: 0,
    lastNumberOfProjects: 0,
    commitTimes: [],
    timesheetData: [],
    projectWorkingTimePieData: [],
    laborCostPieData: [],
    performanceLineData: [],
    salaryBarData: [],
  }
  averageSkill: ISkill
  skill: ISkill
  constructor(rootStore: RootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  public async updateProfile(data: IProfileDetailOfDetailForm): Promise<void> {
    try {
      const parsedValues: IUser = getParsedUserValueBeforeSendBE(data)
      if (!parsedValues) {
        return
      }
      await updateUserProfileAPI(parsedValues)
      this.getProfileDetail()
      toast.success(Messages.updateProfileSuccess)
      if (window) {
        window.localStorage.setItem('userRole', String(data?.role))
        window.localStorage.setItem('email', data?.email)
        window.localStorage.setItem('fullName', data?.fullName)
        window.localStorage.setItem('displayName', data?.displayName)
        window.localStorage.setItem('userTitle', data?.title?.value)
        window.localStorage.setItem('avatar', data?.avatar)
      }
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'updateProfile', false)
    }
  }

  public async getProjectNameList(condition: string, callback?: Function): Promise<void> {
    try {
      const response: ISuccessResponse<IProjectNameListResponse> = await getAllNameProjectListOfUserAPI(condition)
      this.projectNameList = response?.data?.projects
      if (callback && typeof callback === 'function') {
        callback()
      }
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'getProjectNameList')
    }
  }
  public async getTechnologyList(): Promise<void> {
    try {
      const { technologies } = await getTechnologyListAPI()
      this.technologyList = technologies
    } catch (error) {
      handleError(error, 'src/store/admin/AdminTechnologyStore.ts', 'getTechnologyList')
    }
  }
  public async getProfileDetail(): Promise<void> {
    try {
      const response: ISuccessResponse<IUserProfileResponse> = await getUserProfileAPI()
      const currentUserData: IUser = response?.data?.user
      const currentUser: IFormattedProfileBeforeRenderFE = getParsedUserValueBeforeRenderFEV2(
        currentUserData,
        this.projectNameList,
        true,
        this.technologyList
      )
      const formattedData: IProfileDetailOfDetailForm = {
        ...currentUser.profileDetailData,
        joinDateUser: moment(currentUser.profileDetailData?.joinDate ?? '').format('DD/MM/YYYY'),
      }
      this.profileDetail = formattedData
      trackIdentify(currentUserData._id, currentUserData.email, currentUserData.fullName)
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'getProfileDetail')
    }
  }

  public getProjectRatings = async () => {
    const [hourlyProjectRatings, fixedProjectRatings] = await Promise.all([
      this.getHourlyRatePriceData(),
      this.getFixedRatePriceData(),
    ])
    this.projectRatings = {
      hourlyProjectRatings,
      fixedProjectRatings,
    }
  }

  private getHourlyRatePriceData = async () => {
    const data = await getHourlyProjectRatingV2API()
    const hourlyRatingPriceData = data.hourlyRatingPriceData
    return hourlyRatingPriceData
  }
  private getFixedRatePriceData = async () => {
    const data = await getFixedProjectRatingV2API()
    const fixedRatingPriceData = data.fixedRatingPriceData
    return fixedRatingPriceData
  }

  public async changePassword(data: IFormChangePassword): Promise<void> {
    try {
      const response: ISuccessResponse<IChangePasswordResponse> = await changePasswordAPI(data)
      const { messages, isSuccess } = response.data
      if (isSuccess) {
        toast.success(messages)
        setTimeout(() => {
          window.localStorage.clear()
          window.location.reload()
        }, 3000)
      }
    } catch (error) {
      handleError(error, 'src/store/profileStore.ts', 'changePassword', false, true)
    }
  }

  public updateUserData = async (data: string) => {
    try {
      const dataObject: object = parseJson(data)
      const response: ISuccessResponse<IUpdateUserProfileResponse> = await updateUserDataAPI(dataObject)
      toast.success(response?.data?.messages)
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'updateUserData')
    }
  }

  public async fetchDashboardUser(period: EWorkingHourPeriods, date: string, userId: string): Promise<void> {
    try {
      this.isDashboardLoading = true
      const data: IDashboardUser = await getDashboardUser(period, date, userId)
      this.dashboardData = data
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'fetchDashboardUser')
    } finally {
      this.isDashboardLoading = false
    }
  }

  public async getIsSupervisorOfUser(): Promise<void> {
    try {
      const data: boolean = await getIsSupervisorAPI()
      this.isSupervisor = data
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'fetchDashboardUser')
    }
  }

  public async getAverageSkill(): Promise<void> {
    try {
      const data: ISkill = await getAverageSkillAPI()
      this.averageSkill = data
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'getAverageSkill')
    }
  }
  public async getSkill(): Promise<void> {
    try {
      const data: ISkill = await getSkillAPI()
      this.skill = data
    } catch (error) {
      handleError(error, 'src/store/user/profileStore.ts', 'getSkill')
    }
  }
}

export default UserProfileStore
